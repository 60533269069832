export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Packages"])},
        "resourceDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard provides a set of pre-verified resource packages."])},
        "resourceDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also refer to https://github.com/eip-work/kuboard-spray-resource to create your own resource package."])},
        "kuboardspray_version_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray min version"])},
        "kuboardspray_version_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray max version"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "container_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported Container Engine"])},
        "supported_os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported OS"])},
        "kube_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K8S Version"])},
        "kubespray_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubespray Version"])},
        "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
        "import_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import status"])},
        "import_status_true": true,
        "import_status_false": false,
        "minVersionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min version required to kuboardspray"])},
        "cannot_reach_online_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current browser cannot reach https://kuboard-spray.cn/support"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包列表"])},
        "resourceDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard 提供一组经过预先测试验证的资源包列表，可以帮助您快速完成集群安装"])},
        "resourceDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您也可以参考项目 https://github.com/eip-work/kuboard-spray-resource 自己创建资源包"])},
        "kuboardspray_version_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray最低版本"])},
        "kuboardspray_version_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray最高版本"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包版本"])},
        "container_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持的容器引擎"])},
        "supported_os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持的操作系统"])},
        "kube_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K8S 版本"])},
        "kubespray_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubespray 版本"])},
        "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导 入"])},
        "import_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入状态"])},
        "import_status_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已导入"])},
        "import_status_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未导入"])},
        "minVersionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray最低版本要求"])},
        "cannot_reach_online_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前浏览器不能在线获取可选的资源包列表，请在可以访问外网的浏览器打开地址 https://kuboard-spray.cn/support"])}
      }
    }
  })
}
