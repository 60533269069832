export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "createByOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Resource Package without internet connection"])}
      },
      "zh": {
        "createByOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离线加载资源包"])}
      }
    }
  })
}
